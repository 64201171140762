import { Action } from '@ngrx/store';

export enum EUploadActions {
  UploadAccountImage = '[UPLOAD] Upload Account Image',
  UploadAccountImageSuccess = '[UPLOAD] Upload Account Image Success',

  UploadFeaturedImage = '[UPLOAD] Upload Featured Image',
  UploadFeaturedImageSuccess = '[UPLOAD] Upload Featured Image Success',

  UploadCategoryImage = '[UPLOAD] Upload Category Image',
  UploadCategoryImageSuccess = '[UPLOAD] Upload Category Image Success',

  UploadUserImage = '[UPLOAD] Upload User Image',
  UploadUserImageSuccess = '[UPLOAD] Upload User Image Success',
}

// Upload Account Image

export class UploadAccountImage implements Action {
  public readonly type = EUploadActions.UploadAccountImage;

  constructor(public file: File) {}
}

export class UploadAccountImageSuccess implements Action {
  public readonly type = EUploadActions.UploadAccountImageSuccess;

  constructor() {}
}

// Upload Featured Image

export class UploadFeaturedImage implements Action {
  public readonly type = EUploadActions.UploadFeaturedImage;

  constructor(public file: File) {}
}

export class UploadFeaturedImageSuccess implements Action {
  public readonly type = EUploadActions.UploadFeaturedImageSuccess;

  constructor() {}
}

// Upload Category Image

export class UploadCategoryImage implements Action {
  public readonly type = EUploadActions.UploadCategoryImage;

  constructor(
    public category_id: string,
    public file: File
  ) {}
}

export class UploadCategoryImageSuccess implements Action {
  public readonly type = EUploadActions.UploadCategoryImageSuccess;

  constructor() {}
}

// Upload User Image

export class UploadUserImage implements Action {
  public readonly type = EUploadActions.UploadUserImage;

  constructor(
    public user_id: string,
    public file: File
  ) {}
}

export class UploadUserImageSuccess implements Action {
  public readonly type = EUploadActions.UploadUserImageSuccess;

  constructor() {}
}

export type UploadActions =
  | UploadAccountImage
  | UploadAccountImageSuccess
  | UploadFeaturedImage
  | UploadFeaturedImageSuccess
  | UploadCategoryImage
  | UploadCategoryImageSuccess
  | UploadUserImage
  | UploadUserImageSuccess;
