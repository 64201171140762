import { Action } from '@ngrx/store';
import { PaginationHolder } from '@models/pagination.model';
import { IBookingHourModel } from '@models/booking-hour.model';
import { ISMSSettingsModel } from '@models/sms-settings.model';
import { IUpdateSmsSettingsRequestModel } from '@models/request/update-sms-settings.request.model';
import { ISMSTemplateModel } from '@models/sms-template.model';
import { IUpdateSMSTemplateRequestModel } from '@models/request/update-sms-template.request.model';
import { IBookingThemeSettings } from '@models/booking-theme-settings.model';
import { IScheduleModel, IScheduleUserWorkingHourModelCreate, IScheduleUserWorkingHourModelPatch } from '@models/schedule.model';

export enum ESettingsActions {
  FetchBookingHours = '[SETTINGS] Fetch Booking Hours',
  FetchBookingHoursSuccess = '[SETTINGS] Fetch Booking Hours Success',

  ChangeBookingHours = '[SETTINGS] Change Booking Hours',
  ChangeBookingHoursSuccess = '[SETTINGS] Change Booking Hours Success',

  FetchSchedule = '[SETTINGS] Fetch Schedule',
  FetchScheduleSuccess = '[SETTINGS] Fetch Schedule Success',

  CreateSchedule = '[SETTINGS] Create Schedule',
  CreateScheduleSuccess = '[SETTINGS] Create Schedule Success',

  PatchSchedule = '[SETTINGS] Patch Schedule',
  PatchScheduleSuccess = '[SETTINGS] Patch Schedule Success',

  DeleteSchedule = '[SETTINGS] Delete Schedule',
  DeleteScheduleSuccess = '[SETTINGS] Delete Schedule Success',

  FetchBookingThemeSettings = '[SETTINGS] Fetch Booking Theme Settings',
  FetchBookingThemeSettingsSuccess = '[SETTINGS] Fetch Booking Theme Settings Success',

  UpdateBookingThemeSettings = '[SETTINGS] Update Booking Theme Settings',
  UpdateBookingThemeSettingsSuccess = '[SETTINGS] Update Booking Theme Settings Success',

  FetchSMSSettings = '[SETTINGS] Fetch SMS Settings',
  FetchSMSSettingsSuccess = '[SETTINGS] Fetch SMS Settings Success',

  UpdateSMSSettings = '[SETTINGS] Update SMS Settings',
  UpdateSMSSettingsSuccess = '[SETTINGS] Update SMS Settings Success',

  FetchSMSTemplates = '[SETTINGS] Fetch SMS Templates',
  FetchSMSTemplatesSuccess = '[SETTINGS] Fetch SMS Templates Success',

  UpdateSMSTemplate = '[SETTINGS] Update SMS Template',
  UpdateSMSTemplateSuccess = '[SETTINGS] Update SMS Template Success',

  FetchSMSPrice = '[SETTINGS] Fetch SMS Price',
  FetchSMSPriceSuccess = '[SETTINGS] Fetch SMS Price Success',

  SetSidebarVisibility = '[SETTINGS] Set Sidebar Visibility',
  SetCalendarScale = '[SETTINGS] Set Calendar Scale',
}

// Fetch Booking Hours

export class FetchBookingHours implements Action {
  public readonly type = ESettingsActions.FetchBookingHours;

  constructor() {}
}

export class FetchBookingHoursSuccess implements Action {
  public readonly type = ESettingsActions.FetchBookingHoursSuccess;

  constructor(public hours: PaginationHolder<IBookingHourModel>) {}
}

// Create Booking Hours

export class ChangeBookingHours implements Action {
  public readonly type = ESettingsActions.ChangeBookingHours;

  constructor(
    public toCreate: IBookingHourModel[],
    public toUpdate: IBookingHourModel[],
    public toDelete: IBookingHourModel[]
  ) {}
}

export class ChangeBookingHoursSuccess implements Action {
  public readonly type = ESettingsActions.ChangeBookingHoursSuccess;

  constructor() {}
}

// Fetch Schedule

export class FetchSchedule implements Action {
  public readonly type = ESettingsActions.FetchSchedule;

  constructor(
    public from_date: string,
    public to_date: string
  ) {}
}

export class FetchScheduleSuccess implements Action {
  public readonly type = ESettingsActions.FetchScheduleSuccess;

  constructor(public schedule: IScheduleModel[]) {}
}

// Crate Schedule

export class CreateSchedule implements Action {
  public readonly type = ESettingsActions.CreateSchedule;

  constructor(
    public reqData: IScheduleUserWorkingHourModelCreate,
    public weekStart: string,
    public weekEnd: string
  ) {}
}

export class CreateScheduleSuccess implements Action {
  public readonly type = ESettingsActions.CreateScheduleSuccess;

  constructor() {}
}

// Patch Schedule

export class PatchSchedule implements Action {
  public readonly type = ESettingsActions.PatchSchedule;

  constructor(
    public id: string,
    public reqData: IScheduleUserWorkingHourModelPatch,
    public weekStart: string,
    public weekEnd: string
  ) {}
}

export class PatchScheduleSuccess implements Action {
  public readonly type = ESettingsActions.PatchScheduleSuccess;

  constructor() {}
}

// Delete Schedule

export class DeleteSchedule implements Action {
  public readonly type = ESettingsActions.DeleteSchedule;

  constructor(
    public id: string,
    public weekStart: string,
    public weekEnd: string
  ) {}
}

export class DeleteScheduleSuccess implements Action {
  public readonly type = ESettingsActions.DeleteScheduleSuccess;

  constructor() {}
}

// Fetch Booking Theme Settings

export class FetchBookingThemeSettings implements Action {
  public readonly type = ESettingsActions.FetchBookingThemeSettings;

  constructor() {}
}

export class FetchBookingThemeSettingsSuccess implements Action {
  public readonly type = ESettingsActions.FetchBookingThemeSettingsSuccess;

  constructor(public settings: IBookingThemeSettings) {}
}

// Update Booking Theme Settings

export class UpdateBookingThemeSettings implements Action {
  public readonly type = ESettingsActions.UpdateBookingThemeSettings;

  constructor(public settings: IBookingThemeSettings) {}
}

export class UpdateBookingThemeSettingsSuccess implements Action {
  public readonly type = ESettingsActions.UpdateBookingThemeSettingsSuccess;

  constructor() {}
}

// Fetch SMS Settings

export class FetchSMSSettings implements Action {
  public readonly type = ESettingsActions.FetchSMSSettings;

  constructor() {}
}

export class FetchSMSSettingsSuccess implements Action {
  public readonly type = ESettingsActions.FetchSMSSettingsSuccess;

  constructor(public settings: ISMSSettingsModel) {}
}

// Update SMS Settings

export class UpdateSMSSettings implements Action {
  public readonly type = ESettingsActions.UpdateSMSSettings;

  constructor(public reqData: IUpdateSmsSettingsRequestModel) {}
}

export class UpdateSMSSettingsSuccess implements Action {
  public readonly type = ESettingsActions.UpdateSMSSettingsSuccess;

  constructor() {}
}

// Fetch SMS Templates

export class FetchSMSTemplates implements Action {
  public readonly type = ESettingsActions.FetchSMSTemplates;

  constructor() {}
}

export class FetchSMSTemplatesSuccess implements Action {
  public readonly type = ESettingsActions.FetchSMSTemplatesSuccess;

  constructor(public templates: PaginationHolder<ISMSTemplateModel>) {}
}

// Update SMS Template

export class UpdateSMSTemplate implements Action {
  public readonly type = ESettingsActions.UpdateSMSTemplate;

  constructor(
    public id: string,
    public reqData: IUpdateSMSTemplateRequestModel
  ) {}
}

export class UpdateSMSTemplateSuccess implements Action {
  public readonly type = ESettingsActions.UpdateSMSTemplateSuccess;

  constructor() {}
}

// Fetch SMS Settings

export class FetchSMSPrice implements Action {
  public readonly type = ESettingsActions.FetchSMSPrice;

  constructor() {}
}

export class FetchSMSPriceSuccess implements Action {
  public readonly type = ESettingsActions.FetchSMSPriceSuccess;

  constructor() {}
}

// Set Sidebar Visibility

export class SetSidebarVisibility implements Action {
  public readonly type = ESettingsActions.SetSidebarVisibility;

  constructor(public isVisible: boolean) {}
}

// Set Calendar Zoom

export class SetCalendarScale implements Action {
  public readonly type = ESettingsActions.SetCalendarScale;

  constructor(public direction: 'increase' | 'decrease') {}
}

export type SettingsActions =
  | FetchBookingHours
  | FetchBookingHoursSuccess
  | ChangeBookingHours
  | ChangeBookingHoursSuccess
  | FetchSchedule
  | FetchScheduleSuccess
  | CreateSchedule
  | CreateScheduleSuccess
  | PatchSchedule
  | PatchScheduleSuccess
  | DeleteSchedule
  | DeleteScheduleSuccess
  | FetchBookingThemeSettings
  | FetchBookingThemeSettingsSuccess
  | UpdateBookingThemeSettings
  | UpdateBookingThemeSettingsSuccess
  | FetchSMSSettings
  | FetchSMSSettingsSuccess
  | UpdateSMSSettings
  | UpdateSMSSettingsSuccess
  | FetchSMSTemplates
  | FetchSMSTemplatesSuccess
  | UpdateSMSTemplate
  | UpdateSMSTemplateSuccess
  | FetchSMSPrice
  | FetchSMSPriceSuccess
  | SetSidebarVisibility
  | SetCalendarScale;
