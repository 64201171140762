import { ISettingsState } from '@core/store/settings/settings.state';
import { createSelector } from '@ngrx/store';
import { IRootState } from '@core/store/root.state';

const _selectSettings = (state: IRootState) => state.settings;

export const selectSettingsIsLoading = createSelector(_selectSettings, (state: ISettingsState) => state.isLoading);
export const selectSidebarVisibility = createSelector(_selectSettings, (state: ISettingsState) => state.isSidebarVisible);
export const selectSettingsBookingHours = createSelector(_selectSettings, (state: ISettingsState) => state.booking_hours);
export const selectSettingsSMSSettings = createSelector(_selectSettings, (state: ISettingsState) => state.smsSettings);
export const selectSMSTemplates = createSelector(_selectSettings, (state: ISettingsState) => state.smsTemplates);
export const selectBookingThemeSettings = createSelector(_selectSettings, (state: ISettingsState) => state.bookingThemeSettings);
export const selectSchedule = createSelector(_selectSettings, (state: ISettingsState) => state.schedule);
export const selectCalendarScale = createSelector(_selectSettings, (state: ISettingsState) => state.scale ?? 3);